export const localSet = <T>(key: string, value: T) => {
  return localStorage.setItem(key, JSON.stringify(value))
}
export const localGet = <T>(key: string, defaultValue?: T) => {
  const stringValue = localStorage.getItem(key)
  return stringValue ? JSON.parse(stringValue) : defaultValue
}
export const sessionSet = <T>(key: string, value: T) => {
  return sessionStorage.setItem(key, JSON.stringify(value))
}
export const sessionGet = <T>(key: string, defaultValue?: T) => {
  const stringValue = sessionStorage.getItem(key)
  return stringValue ? JSON.parse(stringValue) : defaultValue
}
