
import {
  COUNT_VIEW_MODEL_KEY,
  WS_HOST
} from "@/assets/script/constant/config.constant"
import { sleep } from "@/assets/script/utils/heighten.util"
import { nextTick, readonly, ref } from "vue"
import { localGet } from "@/assets/script/utils/storage.util"
import { ExpoConfigViewModel } from "@/assets/script/model/main.model"
import { GravityRealtimeData } from "@/views/gravity/constants"
import echarts from "echarts"
import { ECHARTS_GRID } from "@/views/report/script/constant"
import { format } from "@/assets/script/utils/date.util"

class Item {
  time: number
  weight: number

  constructor(time: number, weight: number) {
    this.time = time
    this.weight = weight
  }
}

export default {
  setup() {
    const webSocket = new WebSocket(WS_HOST)
    const list = ref<Item[]>([])
    const props = readonly(
      localGet(COUNT_VIEW_MODEL_KEY, new ExpoConfigViewModel())
    )
    let dom: HTMLDivElement
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let echartsDom: echarts.ECharts
    const setRef = (el: HTMLDivElement) => (dom = el)
    const option = {
      grid: ECHARTS_GRID,
      xAxis: {
        type: "category",
        data: [],
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          textStyle: {
            color: "rgba(255,255,255,.75)" //更改坐标轴文字颜色
          }
        }
      },
      yAxis: [
        {
          type: "value",
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: "rgba(255,255,255,.75)" //更改坐标轴文字颜色
            },
            formatter(value: number) {
              return (value / 1000).toFixed(1)
            }
          },
          splitLine: {
            lineStyle: {
              color: "rgba(19, 43, 81, .3)",
              width: 1,
              type: "solid"
            }
          }
        }
      ],
      series: [
        {
          data: [],
          type: "line",
          symbol: "none",
          itemStyle: {
            normal: {
              color: "#5cabd2", //改变折线点的颜色
              lineStyle: {
                color: "#5cabd2" //改变折线颜色
              }
            }
          }
        }
      ],
      stateAnimation: {
        duration: 0
      },
      animationDuration: 0,
      animationDurationUpdate: 0
    }
    const draw = (item: Item) => {
      // 30个点
      if (list.value.length >= 30) {
        const [_, ...newList] = list.value
        newList.push(item)
        list.value = newList
      } else {
        list.value.push(item)
      }
      option.xAxis.data = list.value.map((it) =>
        format(new Date(it.time), "ss")
      ) as never[]
      option.series[0].data = list.value.map((it) => it.weight) as never[]
      echartsDom.setOption(option as echarts.EChartOption)
    }

    webSocket.onmessage = (result) => {
      const data: GravityRealtimeData = JSON.parse(result.data)
      if (data.type === "deviceGravityData") {
        draw(new Item(data.time, data.weight))
      }
    }
    const sendRealtime = async () => {
      if (webSocket.OPEN) {
        webSocket.send(
          JSON.stringify({
            method: "bindASMBed",
            mainBoardId: props.mainBoardId,
            bedId: props.bedId
          })
        )
      } else {
        await sendRealtime()
      }
    }

    webSocket.onopen = () => {
      setInterval(() => {
        webSocket.send(JSON.stringify({ method: "pong" }))
      }, 3000)
      sendRealtime()
    }

    nextTick(() => {
      echartsDom = echarts.init(dom)
      echartsDom.setOption(option as echarts.EChartOption)
    })
    return {
      list,
      setRef
    }
  }
}
